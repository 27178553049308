// regmtait.co.uk website styles  
// sass --sourcemap=none --watch regmtait-main.scss:regmtait-main.css --style nested
// /Users/Reg/Dropbox/WORK/Reg Tait/regmtait website/WEBDEV/css updates plus sass

$font-stack-mono: Andale Mono,AndaleMono,monospace;
$font-bold: 700;
$theme-pink: #df2854;
$border-colour: #cacaca;
$html-bg: #a7a7a7;
$bg: #efefef;
$pre-bg: #fff;
$code: darken($theme-pink, 10%);
$text-colour: #000;
$text-lighter: #888;
$link-body: #666;
$highlight: #eceda9;
$selection: #eceda9;
$theme1: $theme-pink;

// ------------------- basic typography ------------------- 


a, a:visited {
	color: $text-colour;
	transition:color 0.2s;
	text-decoration: none;
	}

a:hover, li.current a {
	color: $theme1;
}

h1 {
	margin: 0 0 1.5rem 0; padding: 0;
	font-size: 1rem;
	font-weight: $font-bold;
	s {
		font-weight: normal;
	}
}

h2 {
	font-size: 1rem;
	margin-bottom: 1rem;
	line-height: 1.5rem;
	font-weight: $font-bold;
}

h3 {
	font-size: 1rem;
}

em {
	font-style: normal;
	background-color: $highlight;
	padding-left: 0.2rem;
	padding-right: 0.2rem;
}

img 	{height: auto;}

.glyph {font-family: sans-serif;}

section.content p a {color: $link-body;}
section.content p a:hover {color: $theme1;}

pre {
	background-color:  $pre-bg;
	padding: 0.5em 1em;
	border-radius: 4px;
	white-space: pre-wrap;
	z-index: 1;
	overflow: hidden;
	position: relative;
	border-left: 2px solid $theme1;
	box-shadow: 2px 3px 3px rgba(0,0,0, 0.07);
}

code {	
	font-size: 65%;
	line-height: 1;
	color: $code;
	z-index: 100;
	position: relative;
	font-family: Consolas,monaco,monospace;
}

blockquote {
	margin-left: 1em;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

// ------------------- main wrappers -------------------

html, button, select, textarea {
	color: $text-colour;
	font-size: 16px;
	line-height: 1.5rem;
	line-height: 1.4rem;
}

html {
	border: 1rem solid white;
	background-color: $html-bg;
	font-family: 'Rubik', -apple-system, sans-serif;
}

body {
	background-color: $bg;
	margin: 0;
	padding: 2rem;
	overflow-x: hidden;
	// transform: rotate(180deg);
}

h1, h2, h3, h4 {
	font-weight: $font-bold;
}

section.content, section.babble, div.wrap {
	margin: 0 auto;
	padding-top: 1rem;
	padding-bottom: 1rem;
	clear: both;
}

section.content {
	overflow: hidden;
	position: relative;
}

::selection {background-color: $selection;}
::-moz-selection {background-color: $selection;} 

// ------------------- header ------------------- 

header {margin: 0;}

header h1 span {
	color: $text-lighter;
	padding-left: 1en;
	font-weight: normal;
}

header h1 span.tagline:before {
    content: "[";
    }
header h1 span.tagline:after {
    content: "]";
    }
    

// ------------------- homepage twitter and blog  -------------------

section.babble {
	padding-top: 1.5rem;
}

section.babble article.blog h1 {
	margin-bottom: 1rem;
}

div.twitter h2 {
  margin-top: 2rem;
  	margin-top: 1.35rem;
	font-weight: $font-bold;
}

div.twitter ul {
	list-style: none;
	margin: 0; padding: 0;
}

.tweet {
    word-wrap: break-word;
}

.interact {
	display: none;
}

// ------------------- article content  -------------------

 
section aside, section div.extratext {
	font-size: 80%; color: $text-lighter;
	}

div.extratext {
	margin-top: 2rem;
	border-top: 1px solid $border-colour;
	padding-top: 1.5rem;
	}

a.extra:before {content: "[";}
a.extra:after {content: "]";}


section aside h2, section div.extratext h2 {
	color: #7e7e7e;
}

section aside ul, section aside ul li, section div.extratext ul, section div.extratext ul li {
	margin-left: 0;
	padding-left: 0;
	list-style: none;
	}
	
section aside ul li, section div.extratext ul li {
	margin-bottom: 1rem;
}
section aside ul li:last-child {
	margin-bottom: 0;
}

section aside dl dt {
	margin-top: 1rem;
	color: #7e7e7e;
	color: $theme1;
}
section aside dl dd {
	margin-left: 0;
}
section.blog aside {
	margin-bottom: 2rem;
	border-bottom: 1px solid #fff;
	border-top: 1px solid #fff;
}

section.casestudy article img {
	max-width: 100%;
	height: auto;
}

section.blog article h2, section.general-content article h2, section.casestudy article h2 {
	font-size: 1.25rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
	margin-top: 2.5rem;
	margin-bottom: 1.5rem;
}

section.content div.article-list article h1 {
	font-size: 1.25rem;
	line-height: 1.5;
	margin-bottom: 1rem !important;
}

div.article-list article {
	margin-bottom: 3rem;
}

p.more {
	margin-top: 2rem;
	margin-bottom: 0;
	color: $text-lighter;
}

article p.more {
    font-size: 75%;
}

header h1 span.js-menu-toggle {display: none;}


figcaption {
    font-size: 75%;
    color: $text-lighter;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #fff;
    text-align: center;
    line-height: 1.4;
}
  
// ------------------- footer ------------------- 

footer {margin: 0;
	border-top: 1px solid $border-colour;
}

footer ul li.email:before {
    content: "[";
    }
footer ul li.email:after {
    content: "].";
    }

footer a span {
	transition:color 0.2s;
}
    
//  ------------------- misc styles  -------------------

article.maincontent {
	z-index: 100;
	position: relative;
}

section.content h1 {
	font-size: 1.5rem;
	line-height: 2rem;
	margin-bottom: 2rem;
}

img.content-image {
	z-index: 5;
}

@media screen and (min-width: 480px) {
	.two-col {
	-webkit-column-count: 2;
	-webkit-column-gap: 1.5rem;
	 -moz-column-count: 2;
    -moz-column-gap: 1.5rem;
	column-count: 2;
	column-gap: 1.5rem;
	}
}

// ------------------- nav -------------------

header ul {
	list-style: none;
	margin: 1rem 0 1rem 0;
	padding: 1rem 0 1rem 0;
	border-top: 1px solid $border-colour;
	border-bottom: 1px solid $border-colour;
}

header ul li {
	padding: 0;
	display: inline-block;
	margin-right: 1rem;
}

header ul li:after {
    content: ".";
} 

.about a.about,
.blog a.blog,
.projects a.projects,
.contact a.contact {
	color: $theme1 !important;
}

// ------------------- homepage portfolio -------------------

div.portfolio ul {
	list-style: none;
	margin: 0 0 0 0;
	padding: 0 0 0 0;
	position: relative;
	z-index: 20;
}  

div.portfolio ul li {
	display: inline;
	margin-right: 1rem;
}
div.portfolio ul li img {
	border-bottom: 1px solid $border-colour;
	opacity: 0.85;
	transition:opacity 0.2s;
}
div.portfolio ul li img:hover {
	opacity: 1;
	}

div.portfolio p {
	margin-top: 3rem;
}

// ------------------- babble -------------------

section.babble {
	border-top: 1px solid $border-colour;
}

// ------------------- article img ------------------- 

.blog-content img, .general-content img {
	width: 100%;
	max-width: 800px;
	margin: 0 auto 1rem auto;
}

// ------------------- blog ------------------- 

article div.blog-intro {
	color: $text-lighter;
}

article div.blog-intro span.date {
	padding-left: 1en;
}

div.blog-summary h1 {
	font-size: 1.25rem;
	line-height: 1.5rem;
}
  
article div.blog-intro span.date:before {content: "[";}
article div.blog-intro span.date:after {content: "]";}

// ------------------- projects ------------------- 
	
	div.projects article {
		margin-bottom: 1rem;	
		}
		
	div.projects img {
		width: 100%;
		height: auto;
		border-bottom: 1px solid $border-colour;
		opacity: 0.85;
		transition:opacity 0.2s;
	}
	
	div.projects a:hover img {
		opacity:1;
	}

	div.projects div.description p,
	div.projects a div.description p,	
	div.projects a:hover div.description p {
		color: $text-lighter;
	}
	
	div.projects div.description h2 {
		margin-bottom: 0.5rem;
		margin-top: 0.5rem;
		font-weight: $font-bold;
	}
	
	div.projects div.description p {
		margin-top: 0;
		font-size: 0.8rem;
		line-height: 1.2rem;
	}
	  
// ------------------- footer ------------------- 
 
footer {
	color: $text-lighter;
 }
 
footer ul {
 	padding: 2rem 0 0 0;
 	list-style: none;
 	font-size: 0.8rem;
 	li {display: inline;}
}

footer ul li.twitter a:hover {
 	color: #4099ff;
 }

footer ul li.instagram a:hover {
 	color: #21537d;
 }

footer ul li.flickr a:hover {
	color: $text-lighter;
}

footer ul li.flickr a:hover span.blue {
 	color: #0161db;
 } 
footer ul li.flickr a:hover span.pink {
 	color: #f70482;
 }
 
footer ul li a, footer ul li a:visited {
     color: $text-lighter;
 }
 
footer ul li:after {
    content: ". ";
}

// -- BxSlider -----

ul.bxslider, ul.bxslider li {
	margin:0; padding: 0;
}

.bx-wrapper {
	position: relative;
	margin: 2rem auto 4rem auto;
	padding: 0;
	*zoom: 1;
}

.bx-wrapper img {
	max-width: 100%;
	display: block;
}

// THEME

.bx-wrapper .bx-viewport {
	background: #efefef;
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
	position: absolute;
	bottom: -3rem;
	width: 100%;
}

// LOADER

.bx-wrapper .bx-loading {
	min-height: 50px;
	background: url(/theme/bx_loader.gif) center center no-repeat #fff;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

// PAGER

.bx-wrapper .bx-pager {
	text-align: center;
	font-size: .85em;
	font-weight: $font-bold;
	color: $text-lighter;
	padding-top: 20px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	*zoom: 1;
	*display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
	background: $text-lighter;
	text-indent: -9999px;
	display: block;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	outline: 0;
	border-radius: 2px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
	background: $theme1;
}

// DIRECTION CONTROLS (NEXT / PREV)

.bx-wrapper .bx-prev {
	left: 10px;
	background: url(/theme/controls.png) no-repeat 0 -32px;
}

.bx-wrapper .bx-next {
	right: 10px;
	background: url(/theme/controls.png) no-repeat -43px -32px;
}

.bx-wrapper .bx-prev:hover {
	background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
	background-position: -43px 0;
}

.bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 50%;
	margin-top: -16px;
	outline: 0;
	width: 32px;
	height: 32px;
	text-indent: -9999px;
	z-index: 9999;
}

// bx-wrapper .bx-controls-direction a.disabled {display: none;}

h1 span.menu-toggle {display: none;}
h1 span.menu-toggle a:link, h1 span.menu-toggle a:visited {color: $text-lighter;}
h1 span.menu-toggle a:hover {color:$theme1;}

// ------------------------[ Media queries ]------------------------ */


@media only screen and (min-width: 0px) and (max-width: 479px) {

	h1 span.tagline {
		display: none;
	}
	h1 span.menu-toggle {
		display: inline;
	}

	html {
		border: none;
		font-size: 17px;
	}
	
	body {
		padding: 1.5rem;
		background: #efefef;
		background: url(/theme/circle-small.png) #efefef 92% -30px no-repeat;
		background-attachment: fixed;
	}
	
	header h1 {
		font-size: 1.5rem;
		line-height: 2rem;
	}
	
	h1, h2 {
		font-size: 1.25rem;
	}

 	div.portfolio ul li img {
		width: 96%;
		height: auto;
		margin-bottom: 1rem;
		border-bottom: 2px solid $border-colour;
	}

 	div.portfolio ul li {
		margin-right: 0;
	}
	
	nav ul li, footer ul li {
	display: block;
	}
	
	nav ul {
		float: left;
		width: 100%;
	}
	
	nav ul li {
	width: 50%;
	float: left;
	font-size: 1.25rem;
	margin-right: 0;
	line-height: 2rem;
	}
	
	header h1 span.tag {display: none;}
	header h1 span.nav-toggle {display: inline;}
	
	nav.js-hide {display: none;}
	

	header h1 span {display: block;}
	
	img.homepage-image {
	display: none;
	}
	
	div.projects div.description {
	margin-bottom: 2rem;
	}
	
}

// homepage fussy style - narrows the heading and paragraph a bit

@media only screen and (min-width: 780px) and (max-width: 900px) {
	article.home-maincontent p, article.home-maincontent h1 {
		width: 84%;
	}
}

// intermediate homepage

@media only screen and (min-width: 480px) and (max-width: 779.9px) {

 	div.portfolio ul li {
	display: block;
	}

	div.portfolio ul li img {
	width: 40%;
	}
	
	img.homepage-image {
	width: 50% !important;
	right:10px !important;
	position: absolute;
	bottom: 20px;
	bottom: 70px;
	}	
	
	// projects
	
	div.projects article {
	float: left;
	width: 100%;
	clear: both;
	margin-bottom: 2rem;
	}
	div.projects img {
	width: 40%;
	float: left;
	}
	div.projects div.description {
	width: 50%;
	float: left;
	margin-left: 1rem;
	}
	div.projects div.description h2 {
	margin-top: 0;
	}
}

// 'fullscreen' styled homepage

@media only screen and (min-width: 780px) {
	
section.babble:before, section.babble:after {content: " "; /* 1 */display: table; /* 2 */}
section.babble:after {clear: both;}

section.blog article, section.no-aside article {max-width: 44em;}

header {
	border-bottom: 1px solid $border-colour;
	height: 3rem;
}

header:before, header:after {content: " ";display: table;}
header:after {clear: both;}

img.homepage-image {
	position: absolute;
	bottom: 10px;
	right: 2rem;
	right: 0;
	width: 18rem;
	width: 19rem;
}

	header ul {
	margin: 0;
	padding: 0;
	border-top: none;
	border-bottom: none;
}


.c-article {
	width: 75%;
	margin: 0 auto;
}

section.casestudy article, div.article-list {
	width: 70%;
	float: left;
	clear: none;
}	

section.links-content article {
	width: 100%;
}

section.casestudy article p.intro:after {
	content: ".";
}

section aside {
	margin-top: 8rem;
	float: right;
	width: 25%;
	border-left: 1px solid #fff;
	padding-left: 1rem;
	padding-top: 0;	
	}
	section aside h2:before {
	content: "[";
}
section aside h2:after {
	content: "]";
}

body.blog section aside {
	margin-top: 1.5rem;
	margin-bottom: 0;
	border-bottom: none;
	padding-bottom: 0;
	border-top: 0;
}
body.blog section aside p {
	margin-bottom: 0;
}

section aside ul, section aside ul li {
	margin-top: 0;
}

section aside p {
	line-height: 1.5rem;
	margin-top:0;
}

section.content, section.babble, div.wrap {
   max-width: 55.71428em; 
  // 780/14  
   }
    
   div.portfolio ul {
   width: 64%;
   width: 31.5rem;
   }
   
   div.portfolio ul li img {
	width: 30%;
	max-width: 150px;
	}

	div.portfolio ul li:last-child {
		margin-right: 0;
	}
   
	section.babble article.blog {
	max-width: 46rem;
	}
	
	div.twitter {
	float: right;
	width: 30%;
	}	
	
	header nav ul {
	max-width: 60%;
	float: right;
	}
	
	header nav ul li {
	display: inline-block;
	margin-right: 0;
	padding: 0;
	/*width: 6rem;*/
	width: auto;
	margin-left: 1.3rem;
	}
	
	body.home h1 {
	max-width: 400px;
	max-width: 28.57rem;
	}
	
	article.home-maincontent {
	width: 380px;
	width: 31.5rem;
	}
		
	header h1 {
	float: left;
	width: 40%;
	margin-bottom: 5rem;		
	}	
	
	// projects
	
	div.projects article {
		width: 30.6%;
		float: left;
		margin-right: 4%;
		height: 19rem;
		overflow: hidden;
		}
	
	div.projects article:nth-child(3n) {
		margin-right: 0;
	}

}

@media only screen and (max-width: 779px) {
// fancy styling for inline aside elements
section aside {
	margin-top: 2rem;
	border-top: 1px solid $border-colour;
	}
		
section aside h2, section aside dl {margin-top: 1rem;}
section aside dl dt {display: inline; clear: left;}
section aside dl dt:after {content: ":";} 
section aside dl dd {display: inline; margin-right: 0rem;}
section aside dl dd:after {content:".";}
}


@media only screen and (min-width: 1150px) {
html, button, select, textarea {font-size: 17.5px;}

}
